import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import ContentBlock from "../components/ContentBlock"

const PageApp = () => (
  <Layout>
    <PageTitle
      title="Mobile Apps"
      description="Use Towbook from any device! iPhone, Android, etc. You can use it on as many devices as you want to for no extra fee."
    ></PageTitle>
    <ContentBlock className="rich-text">
      <div className="flex flex-col items-center justify-center sm:flex-row lg:justify-start">
        <a
          href="https://apps.apple.com/us/app/towbook/id670258292"
          target="_blank"
          rel="noopener noreferrer me"
        >
          <img src="/app-store.svg" alt="App Store" className="h-16" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.towbook.mobile"
          target="_blank"
          rel="noopener noreferrer me"
          className="mt-6 sm:ml-6 sm:mt-0"
        >
          <img src="/play-store.svg" alt="Google Play Store" className="h-16" />
        </a>
      </div>
    </ContentBlock>
  </Layout>
)

export default PageApp
